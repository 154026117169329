import '../css/front_page.scss';

import * as bootstrap from 'bootstrap';

// cart modal with vanilla js
const cartModalContent = document.querySelector('#cart-modal');
if (cartModalContent) {
	const cartModal = new bootstrap.Modal(cartModalContent, {});

	cartModal.show();
}

// danish link modal with vanilla js
const danishLinkModalContent = document.querySelector('#danish-link-modal');
if (danishLinkModalContent) {
	const danishLinkModal = new bootstrap.Modal(danishLinkModalContent, {});

	danishLinkModal.show();
}

// cart popover
const cartButton = document.querySelector('#cart-popover-button');
if (cartButton) {
	new bootstrap.Popover(cartButton, {
		html: true,
		placement: "bottom",
		container: "body",
		boundary: document.querySelector('main'),
		delay: {
			"hide": 100
		},
		content: function() {
			const content = document.querySelector("#cart-popover-content").cloneNode(true);
			content.removeAttribute("hidden");
			return content;
		},
		template: '<div class="popover popover-cart" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
	});
}

// Intersection Observer API navbar
const navContainer = document.querySelector('.nav-container');
if (navContainer) {
	const handleIntersection = (entries) => {
		entries.map((entry) => {
			if (entry.isIntersecting) {
				navContainer.classList.remove('sticky');
			} else {
				navContainer.classList.add('sticky');
			}
		});
	}

	if (typeof window.IntersectionObserver !== 'undefined') {
		const heroBanner = document.querySelector('#hero');

		const options = { threshold: 0.5 };
		const observer = new IntersectionObserver(handleIntersection, options);

		if (heroBanner !== null) {
			observer.observe(heroBanner);
		}
	}
}
